/* ========================================================================
 * Core v1.0.0
 * Copyright 2016
 * ======================================================================== */
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';

(function () {

    // Create the defaults once
    // ================================
    var pluginName  = 'Core',
        defaults    = {
            eventPrefix: 'fa',
            breakpoint: {
                xl: 1300,
                lg: 1200,
                md: 960,
                sm: 720,
                xs: 540
            }
        },
        isScreenxl = false,
        isScreenlg = false,
        isScreenmd = false,
        isScreensm = false,
        isScreenxs = false;

    // Core MAIN function
    // ================================
    function MAIN(element, options) {
        this.element    = element;
        this.settings   = $.extend({}, defaults, options);
        this._defaults  = defaults;
        this._name      = pluginName;
        this.init();
    }

    // Core MAIN function prototype
    // ================================
    MAIN.prototype = {
        init: function () {
            this.VIEWPORTWATCH();
            this.MISC.Init();
            this.PLUGINS();
        },

        // Viewport watcher
        // ================================
        VIEWPORTWATCH: function () {
            var element = this.element;
            var settings = this.settings;

            Response.action(function () {
                isScreenxl = Response.band(settings.breakpoint.xl);
                isScreenlg = Response.band(settings.breakpoint.xl, settings.breakpoint.lg-1);
                isScreenmd = Response.band(settings.breakpoint.md, settings.breakpoint.lg-1);
                isScreensm = Response.band(settings.breakpoint.sm, settings.breakpoint.md-1);
                isScreenxs = Response.band(0, settings.breakpoint.xs);

                if(isScreenxl) {
                    $(element)
                        .addClass('screen-xl')
                        .removeClass('screen-lg')
                        .removeClass('screen-md')
                        .removeClass('screen-sm')
                        .removeClass('screen-xs');
                }

                if(isScreenlg) {
                    $(element)
                        .removeClass('screen-xl')
                        .addClass('screen-lg')
                        .removeClass('screen-md')
                        .removeClass('screen-sm')
                        .removeClass('screen-xs');
                }

                if(isScreenmd) {
                    $(element)
                        .removeClass('screen-xl')
                        .removeClass('screen-lg')
                        .addClass('screen-md')
                        .removeClass('screen-sm')
                        .removeClass('screen-xs');
                }

                if(isScreensm) {
                    $(element)
                        .removeClass('screen-xl')
                        .removeClass('screen-lg')
                        .removeClass('screen-md')
                        .addClass('screen-sm')
                        .removeClass('screen-xs');
                }

                if(isScreenxs) {
                    $(element)
                        .removeClass('screen-xl')
                        .removeClass('screen-lg')
                        .removeClass('screen-md')
                        .removeClass('screen-sm')
                        .addClass('screen-xs');
                }

                calcFontSize();
                fixedHeader();
                stickNav();
            });

            // textfill
            function calcFontSize() {
                var $brand  =  $('.brand-text');
                var resetStyles = {
                    width : "auto",
                    maxWidth: "100%"
                };

                $brand.removeAttr('style');

                $brand.textfill({
                    widthOnly: false,
                    maxFontPixels: 85,
                    minFontPixels: 25,
                    success: function() {
                        $brand.css(resetStyles);
                    }
                });
            }

            // Fixed header margin
            function calcHeaderMargin() {
                if ($(window).width() < 767) {
                    var header_height = $('#header').height();
                    $('main.container').css({marginTop: header_height + 15});
                }
                else {
                    $('main.container').css({marginTop: 0});
                }
            }
            function fixedHeader() {
                $(document).ready(function() {
                    calcHeaderMargin();
                });
                $(window).resize(function() {
                    calcHeaderMargin();
                });
            }
            // stick the last header nav
            function stickNav() {
                var mainNav = $('#main-navbar');
                var branContainer = $('header#header .banner');
                var mainOffsetTop = mainNav.offset().top;
                var mainNavHeight = mainNav.height() + 12;
                $('#mainMenu').css({top: -mainNavHeight});

                var sticky_active = false;

                $(window).scroll(function() {
                    if ( $(window).scrollTop() > mainOffsetTop && !sticky_active) {
                        sticky_active = true;
                        mainNav.addClass('nav-fixed');
                        mainNav.next().css({ top: mainNavHeight });
                        branContainer.css({marginBottom: mainNavHeight});
                        $('body').addClass('body-sticky-active');
                    } else if($(window).scrollTop() < mainOffsetTop && sticky_active) {
                        sticky_active = false;
                        mainNav.removeClass('nav-fixed');
                        mainNav.next().css({ top: 0 });
                        branContainer.css({marginBottom: 0});
                        $('body').removeClass('body-sticky-active');
                    }
                });
            }
        },

        // Misc
        // ================================
        MISC: {
            // @MISC: Init
            Init: function () {
                this.LazyImages();
                this.prioritynav();
                // this.Filter();
                this.Fastclick();
                this.BsTooltip();
                this.BsPopover();
                this.BsDropdown();
                this.fluidbox();
            },

            // @MISC: Filter
            // Per call
            // ================================
            Filter: function () {
                new Philter({
                    transitionTime: 0.35, // hover transition time
                    tag: true // 'philter' in data attributes
                })
            },

            // @MISC: Fastclick
            // Per call
            // ================================
            Fastclick: function () {
                FastClick.attach(document.body);
            },

            // @MISC: BsTooltip - Bootstrap tooltip
            // Per call
            // ================================
            BsTooltip: function () {
                $('[data-toggle~=tooltip]').tooltip();
            },

            // @MISC: BsPopover - Bootstrap popover
            // Per call
            // ================================
            BsPopover: function () {
                $('[data-toggle~=popover]').popover();
            },

            // @MISC: BsHoverDropdown - Bootstrap hover dropdown
            // Per call
            // ================================
            BsDropdown: function () {
                $('[data-toggle="dropdown"]').dropdown();
            },

            // @MISC: priorityNav - js navbars collapse
            // Per call
            // ================================
            prioritynav: function () {
                priorityNav.init({
                    mainNavWrapper: '[data-toggle="priorityNav"]', // main wrapper
                    navDropdownLabel: '',
                    navDropdownBreakpointLabel: '',
                    breakPoint: 0
                });
            },

            // lightbox
            // ================================
            fluidbox: function() {
                var toggler = 'a[data-toggle=fluidbox]';

                $( toggler ).fluidbox({
                    loader: true,
                    maxWidth: 1200
                });

                $( window ).scroll( function () {
                    $( toggler ).trigger( 'close.fluidbox' );
                });
            },

            // Lazyload Images
            // ================================
            LazyImages: function() {
                $('img[data-src]').unveil(700, function() {
                    $(this).load(function() {
                        this.style.opacity = 1;
                    });
                });
            },
        },

        // Custom Mini Plugins
        // ================================
        PLUGINS: function () {
            var element = this.element;
            var settings = this.settings;

            // @PLUGIN: Weather
            // Self invoking
            // ================================
            (function () {

                var weekdays = new Array('Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vier', 'Sáb');
                var weekLongDays = new Array('Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado');
                var icons = new Array();
                var handlebars_template = Handlebars.compile($('#weather-template').html());
                var handlebars_container = $('#weather-template-container');
                var weather_collapse = '#weather-collapse';
                var page_clima_container = $('#clima-page');
                var round_number = function(number){ return Math.round(number*100)/100 };

                function fetch_data(slug, current_city, toggle) {
                    var fetch_url = '/weather/';

                    // console.log('first slug in weather', slug)

                    if(slug == null) {
                        slug = CITY_SLUG;
                    }

                    if(! toggle == null) {
                        var toggled = false;
                    } else {
                        var toggled = toggle;
                    }

                    if(slug) {
                        fetch_url = fetch_url + '?city=' + slug + ',ar';
                    }
                    if(!current_city) {
                        current_city = CITY;
                    }

                    $.get(fetch_url, function (data) {
                        // Validate that first day not is today
                        next_days = [];
                        next_days_page = [];
                        const dataWeather = data[0];
                        const forecastData = data[1];
                        const todayDay = new Date().getDate();
                        const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];
                        const d  = new Date();
                        var advanceDay =1;
                        for (var i = todayDay+1; i < todayDay+5; i++) {
                            var dayName = days[forecastData[i].day];
                            var forecast_icon = STATIC_URL + "images/weather_icon/" + forecastData[i].icon + ".svg";
                            next_days.push({icon: forecast_icon, day: dayName});
                                next_days_page.push({
                                    icon: forecast_icon,
                                    min: forecastData[i].temperature_min,
                                    max: forecastData[i].temperature_max,
                                    day: dayName,
                                    text: forecastData[i].description,
                                });
                            advanceDay += 1;
                        }

                        context = {
                            icon: dataWeather.weather[0].icon,
                            temperature: Math.floor(dataWeather.main.temp),
                            // // temperature: data['main']['temp'],
                            humidity: dataWeather.main.humidity,
                            wspeed: Math.floor(dataWeather.wind.speed),
                            current_date: handlebars_container.data('cdate'),
                            cities: handlebars_container.data('cities'),
                            next_days:  next_days,
                            city: current_city,
                            STATIC_URL: STATIC_URL
                        }
                        context.imgsrc = STATIC_URL + "images/weather_icon/" + context.icon + ".svg";

                        var page_context = context;
                        page_context.next_days = next_days_page;
                        page_context.visibility = dataWeather.visibility;
                        page_context.pressure = dataWeather.main.pressure;

                        var html = handlebars_template(context);
                        handlebars_container.html(html);

                        if(toggled) {
                            $(weather_collapse).collapse();
                        }

                        // Close actions
                        var close_button = weather_collapse + ' .close-button';

                        $(close_button).on('click', function() {
                            $( weather_collapse ).collapse('hide');
                        });

                        $('#sticy-w').text(context.temperature);

                        // this is to clima page
                        if ( page_clima_container.length > 0 ) {
                            var page_clima_template = Handlebars.compile($('#clima-weather-template').html());
                            var page_html = page_clima_template(page_context);
                            page_clima_container.html(page_html);
                        }

                    })

                }

                function change_city () {
                    var toggler = '#cities-list a';
                    $(handlebars_container).on('click', toggler, function() {
                        var city_name = $(this).data("city");
                        var slug = $(this).data("slug");
                        $(weather_collapse).html('<div class="pa25"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Cargando...</span></div>');
                        fetch_data(slug, city_name, true);
                    });

                    if ( page_clima_container.length > 0 ) {
                        $(page_clima_container).on('click', '.card-change-city', function() {
                            fetch_data($(this).data('slug'), $(this).data('city'), false);
                        });
                    }
                }
                fetch_data();
                change_city();
            })();

            // @PLUGIN: Main Menu
            // Self invoking
            // ================================
            (function () {
                var menuHandler = '[data-toggle~=mainMenu]',
                    mainMenu,
                    openClass   = 'mainMenu-open';

                function open() {
                    $(element).addClass(openClass);
                    $(element).trigger(settings.eventPrefix+'.mainMenu.open', { 'element': $(mainMenu) });
                }

                function close() {
                    if ($(element).hasClass(openClass)) {
                        $(element).removeClass(openClass);
                        $(element).trigger(settings.eventPrefix+'.mainMenu.close', { 'element': $(mainMenu) });
                    }
                }

                function toggle(e) {
                    // open/close Menu
                    if(!$(element).hasClass(openClass)) {
                        open();
                    } else {
                        close();
                    }

                    return false;
                }

                $(document)
                    .on('click', menuHandler, toggle);
            })();

            // @PLUGIN: ToTop
            // Self invoking
            // ================================
            (function () {
                var toggler     = '[data-toggle~=totop]';

                // toggler
                $(element).on('click', toggler, function (e) {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 200);

                    e.preventDefault();
                });
            })();

            // @PLUGIN: WayPoint
            // Self invoking
            // ================================
            (function () {
                var toggler     = '[data-toggle~=waypoints]';

                $(toggler).each(function () {
                    var wayShowAnimation = $(this).data('showanim') || 'fadeIn',
                        wayHideAnimation = $(this).data('hideanim') || false,
                        wayOffset = $(this).data('offset') || '80%',
                        wayMarker = $(this).data('marker') || this,
                        triggerOnce = $(this).data('trigger-once') || false;

                    // waypoints core
                    $(wayMarker).waypoint({
                        handler: function (direction) {
                            //console.log($(wayMarker));
                            if(direction === 'down') {
                                $(wayMarker)
                                    .removeClass(wayHideAnimation + ' animated')
                                    .addClass(wayShowAnimation + ' animating')
                                    .on('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                                        $(this)
                                            .removeClass('animating')
                                            .addClass('animated')
                                            .removeClass(wayShowAnimation);
                                    });
                            }
                            if((direction === 'up') && (wayHideAnimation !== false)) {
                                $(wayMarker)
                                    .removeClass(wayShowAnimation + ' animated')
                                    .addClass(wayHideAnimation + ' animating')
                                    .on('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
                                        $(this)
                                            .removeClass('animating')
                                            .removeClass('animated')
                                            .removeClass(wayHideAnimation);
                                    });
                            }
                        },
                        continuous: true,
                        offset: wayOffset
                    });
                });
            })();

            // @PLUGIN: WayPoints inview
            // Self invoking
            // ================================
            (function () {
                var toggler     = '[data-toggle~=inview]';

                $(toggler).each(function () {
                    var inviewEnterClass    = $(this).data('enter-class') || 'inview-enter',
                        inviewEnteredClass  = $(this).data('entered-class') || 'inview-entered',
                        inviewExitClass     = $(this).data('exit-class') || 'inview-exit',
                        inviewExitedClass   = $(this).data('exited-class') || 'inview-exited';

                    new Waypoint.Inview({
                        element: this,
                        enter: function (direction) {
                            $(this.element)
                                .removeClass(inviewEnteredClass)
                                .removeClass(inviewExitClass)
                                .removeClass(inviewExitedClass)
                                .addClass(inviewEnterClass);
                        },
                        entered: function (direction) {
                            $(this.element)
                                .removeClass(inviewEnterClass)
                                .removeClass(inviewExitClass)
                                .removeClass(inviewExitedClass)
                                .addClass(inviewEnteredClass);
                        },
                        exit: function (direction) {
                            $(this.element)
                                .removeClass(inviewEnterClass)
                                .removeClass(inviewEnteredClass)
                                .removeClass(inviewExitedClass)
                                .addClass(inviewExitClass);
                        },
                        exited: function (direction) {
                            $(this.element)
                                .removeClass(inviewEnterClass)
                                .removeClass(inviewEnteredClass)
                                .removeClass(inviewExitClass)
                                .addClass(inviewExitedClass);
                        }
                    });
                });
            })();

            // @PLUGIN: Slick Carousel
            // Self invoking
            // ================================
            (function () {
                var main_toggler = '.slick-carousel .carousel-pictures',
                    thumbs_toggler = '.slick-carousel .carousel-thumbnails';

                $(main_toggler).slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-arrow slick-prev"><i class="fa fa-2x fa-chevron-left" aria-hidden="true"></i></button>',
                    nextArrow: '<button type="button" class="slick-arrow slick-next"><i class="fa fa-2x fa-chevron-right" aria-hidden="true"></i></button>',
                    fade: false,
                    asNavFor: thumbs_toggler,
                    infinite: false,
                });

                $(thumbs_toggler).slick({
                    lazyLoad: 'ondemand',
                    slidesToShow: 10,
                    slidesToScroll: 1,
                    asNavFor: main_toggler,
                    arrows: false,
                    dots: false,
                    focusOnSelect: true,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 7
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 5
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 4
                            }
                        }
                        ,
                        {
                            breakpoint: 380,
                            settings: {
                                slidesToShow: 3
                            }
                        }
                    ]
                });
            })();

            // @PLUGIN: Panel Collapse
            // Self invoking
            // ================================
            (function () {
                var toggler   = '[data-toggle~=panelcollapse]';

                // clicker
                $(element).on('click', toggler, function (e) {
                    // find panel element
                    var panel   = $(this).parents('.panel'),
                        target  = panel.children('.panel-collapse'),
                        height  = target.height();

                    // error handling
                    if(target.length === 0) {
                        $.error('collapsable element need to be wrap inside ".panel-collapse"');
                    }

                    var open = function (toggler) {
                        $(toggler).removeClass('down').addClass('up');
                        $(target)
                            .removeClass('pull').addClass('pulling')
                            .css('height', '0px')
                            .transition({ height: height }, function() {
                                $(this).removeClass('pulling').addClass('pull out');
                                $(this).css({ 'height': '' });
                            });

                        // publish event
                        $(element).trigger(settings.eventPrefix+'.panelcollapse.open', { 'element': $(panel) });
                    };

                    var close = function (toggler) {
                        $(toggler).removeClass('up').addClass('down');
                        $(target)
                            .removeClass('pull out').addClass('pulling')
                            .css('height', height)
                            .transition({ height: '0px' }, function() {
                                $(this).removeClass('pulling').addClass('pull');
                                $(this).css({ 'height': '' });
                            });

                        // publish event
                        $(element).trigger(settings.eventPrefix+'.panelcollapse.close', { 'element': $(panel) });
                    };

                    // collapse the element
                    if($(target).hasClass('out')) {
                        close(this);
                    } else {
                        open(this);
                    }

                    // prevent default
                    e.preventDefault();
                });
            })();

            // @PLUGIN: Horoscope Widget
            // Self invoking
            // =============================
            (function () {
              $('.list-grid-horoscope .list-item a').on('click', function () {
                $('#js-horoscope-image').attr('src', $(this).data('image'));
                $('#js-horoscope-name').text($(this).data('name'));
                $('#js-horoscope-date').text($(this).data('date'))
                $('#js-horoscope-message').text($(this).data('message'));
                $('#horoscope-content').collapse();
              })
            })();

            // @PLUGIN: Subscribe Widget
            // Self invoking
            // =============================
            (function () {
              $('#subscribe-form').submit(function (event) {
                $.ajax({
                  type: 'post',
                  url: $(this).attr('action'),
                  data: {
                    'name': $(this).find('input[name=name]').val(),
                    'email': $(this).find('input[name=email]').val(),
                  },
                  success: function (msg) {
                    $('#subscribe-form').parent().find('span').text(msg['msg']);
                    $('#subscribe-form').remove()
                  },
                });
                return false;
              })
            })();

            // @PLUGIN: Cover Widget
            // Self invoking
            // =============================
            (function () {
              $('#js-wcover-list span').on('click', function () {
                $('#js-wcover-image').attr('src', $(this).data('image'));
                $('#js-wcover-image + .fluidbox__ghost').css('background-image', 'url(' + $(this).data('image') + ')');
                $('#js-wscover-title').text($(this).text());
                $(this).collapse();
              })
            })();

            // @PLUGIN: Cards view toggler
            // Self invoking
            // =============================
            (function () {
              $('.view-switch button').on('click', function () {
                var section = $(this).parents("section");
                if( $(this).data('toggle') == "list-view" ) {
                    $('.col-deck', section).addClass('list-view');
                    $('[class^="col-"], [class*=" col-"]', section).addClass('full');
                }
                else {
                    $('.col-deck', section).removeClass('list-view');
                    $('[class^="col-"], [class*=" col-"]', section).removeClass('full');
                }
              })
            })();

            // @PLUGIN: Lazy Embed
            // Self invoking
            // ================================
            (function lazy_embed() {

                function appendPlaceholder(parent, src) {
                    $(parent).append('<img class="placeholder" src="' + src + '" alt="Card image">');
                    var placeholder = $('.placeholder', parent);
                    // placeholder.imageScale();
                    return placeholder;
                }

                var toggler = '.lazy-embed';

                $(toggler).each(function (index) {

                    var script = $(this).data('script');
                    var src_placeholder = $(this).data('placeholder');
                    var toggle = $(this).data('toggle'); // The action o type of embed

                    if(toggle == 'jwplayer') {

                        var element = 'playerElement-' + index;
                        $(this).append('<div class="pattern overlay-player"></div>');
                        $(this).append('<div id="' + element + '"></div>');
                        var placeholder = appendPlaceholder(this, src_placeholder);

                        $(this).on('click', function(){

                            if( !$(this).hasClass('active') ) {

                                $(this).addClass('active');
                                var area_height = placeholder.height();
                                $('#'+element, this).height(area_height);
                                placeholder.remove();
                                $(this).append('<div class="loader"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Cargando...</span></div>');
                                var loader = $('.loader', this);
                                $.getScript(script, function(){
                                    jwplayer.key="tYdk8N1y5mRXZ5aT/F8n3uNTC7GilSDcvZLPmWYVqGQ=";
                                    var playerInstance = jwplayer(element);
                                    playerInstance.setup({
                                        "sources": [
                                            {"file": "https://59537faa0729a.streamlock.net:443/telejunin/telejunin/playlist.m3u8"},
                                            {"file": "rtmp://534c549289f32.streamlock.net:1935/9618/9618"}
                                        ],
                                        "skin": {
                                            "name": "seven",
                                            "url": "https://estudiosmax.com/video/seven.css"
                                        },
                                        "autostart": "true",
                                        "controls": true,
                                        "width": "100%",
                                        "height": area_height,
                                        "primary": "html5",
                                        "bufferlength": "3"
                                    });
                                }).done(function( s, status ) {
                                    loader.remove();
                                });
                            }
                        });
                    }
                    if(toggle == 'issuu') {

                        var embed_code = $(this).data('embed-code');
                        var placeholder = appendPlaceholder(this, src_placeholder);

                        $(this).on('click', function(){
                            if( !$(this).hasClass('active') ) {
                                $(this).addClass('active');
                                var area_height = placeholder.height();
                                placeholder.remove();
                                $(this).append('<div class="loader"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Cargando...</span></div>');
                                var loader = $('.loader', this);
                                $(this).append('<div class="issue-widget">' + embed_code + '</div>');
                                $('.issue-widget .issuuembed', this).height(area_height);
                                setTimeout(function() {
                                    loader.remove();
                                }, 3000);
                            }
                        });
                    }
                });
            })();
        }
    };

    // A really lightweight plugin wrapper around the constructor,
    // preventing against multiple instantiations
    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, pluginName)) {
                $.data(this, pluginName, new MAIN(this, options));
            }
        });
    };
})();
// Core init
// NOTE: init at html element
// ================================
(function () {
    $('html').Core();
})();
