// this is in milliseconds.
// 2 mins by default  mins * secs * milisecs
var GETJSON_CACHE_TIME = 2 * 60 * 1000;

jQuery.extend({
  getCachedJSON: function (url, callback) {
    var cacheTimeInMs = GETJSON_CACHE_TIME;
    var currentTimeInMs = new Date().getTime();

    var cache = {
      data: null,
      timestamp: null
    };

    if (typeof window.localStorage[url] !== "undefined") {
      cache = JSON.parse(window.localStorage[url]);

      var validCache = (currentTimeInMs - cache.timestamp) < cacheTimeInMs;

      if (validCache) {
          callback(cache.data);
          return true;
      }
    }

    $.getJSON(url, function (data) {
      cache.data = data;
      cache.timestamp = new Date().getTime();

      window.localStorage[url] = JSON.stringify(cache);

      callback(cache.data);
    });
  }
});

var getUUID4 = function () {
  // I leave this approach just for general culture :)
  // 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
  //     var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
  //     return v.toString(16);
  // });

  function b (a) {return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,b)}
  return b();
}

//  Truncate chars
var truncate =  function (text, nchars) {
  nchars =  nchars || 55; // Default Value
  if (text.length > nchars) {
    return text.substring(0, nchars-3) + '...';
  }
  return text;
}

// Cookies Stuffs

function beforeMidnight () {
    var mid = new Date(),
    ts = mid.getTime();
    mid.setHours(24, 0, 0, 0);
    return Math.floor((mid - ts) / 60000);
}

function setCookie (cname, cvalue) {
    var d = new Date();
    d.setTime(d.getTime() + (beforeMidnight() * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    console.log(expires);
    document.cookie = cname + "=" + cvalue + "; " + expires;
}


function getCookie (cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i=0; i<ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
}
